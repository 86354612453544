"use strict";

angular.module("informaApp")
    .service("MetricsHelper", function () {
        return {
            pos: 'PoS',
            loa: 'LoA',
            duration: 'Duration',
            medianDuration: 'MedianDuration'
        }
    });